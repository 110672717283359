/**
 * @generated SignedSource<<e1930f91715cb6c2527b77a183a823a8>>
 * @relayHash 70a706d89e10675995b5d7fcc510493d
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-finding/480.0.0-2024-09-18T15:35:19.730Z/RecommendedItemsCarouselViewerRefetchQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecommendedItemsCarouselViewerRefetchQuery$variables = {
  loadPortfolioData?: boolean | null;
  pageSize?: number | null;
  recentlyViewedIds?: ReadonlyArray<string | null> | null;
  returnAmount?: number | null;
  userIds?: ReadonlyArray<string | null> | null;
};
export type RecommendedItemsCarouselViewerRefetchQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"RecommendedItemsCarousel_viewer">;
  };
};
export type RecommendedItemsCarouselViewerRefetchQuery = {
  response: RecommendedItemsCarouselViewerRefetchQuery$data;
  variables: RecommendedItemsCarouselViewerRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": false,
    "kind": "LocalArgument",
    "name": "loadPortfolioData"
  },
  {
    "defaultValue": 18,
    "kind": "LocalArgument",
    "name": "pageSize"
  },
  {
    "defaultValue": ([]/*: any*/),
    "kind": "LocalArgument",
    "name": "recentlyViewedIds"
  },
  {
    "defaultValue": 15,
    "kind": "LocalArgument",
    "name": "returnAmount"
  },
  {
    "defaultValue": ([]/*: any*/),
    "kind": "LocalArgument",
    "name": "userIds"
  }
],
v1 = {
  "kind": "Variable",
  "name": "pageSize",
  "variableName": "pageSize"
},
v2 = {
  "kind": "Variable",
  "name": "recentlyViewedIds",
  "variableName": "recentlyViewedIds"
},
v3 = {
  "kind": "Variable",
  "name": "returnAmount",
  "variableName": "returnAmount"
},
v4 = {
  "kind": "Variable",
  "name": "userIds",
  "variableName": "userIds"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  (v5/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "LinkData",
  "kind": "LinkedField",
  "name": "linkData",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLinkable",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "kind": "Literal",
  "name": "page",
  "value": "searchAndBrowse"
},
v12 = [
  (v11/*: any*/)
],
v13 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
],
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "ConvertedAmountList",
  "kind": "LinkedField",
  "name": "convertedAmountList",
  "plural": true,
  "selections": (v13/*: any*/),
  "storageKey": null
},
v15 = [
  {
    "kind": "Literal",
    "name": "formatType",
    "value": "MOBILE"
  }
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v17 = {
  "kind": "Literal",
  "name": "portfolioTypes",
  "value": [
    "HEART"
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecommendedItemsCarouselViewerRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "loadPortfolioData",
                "variableName": "loadPortfolioData"
              },
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "RecommendedItemsCarousel_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecommendedItemsCarouselViewerRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "concreteType": "Item",
            "kind": "LinkedField",
            "name": "recommendedItems",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contemporaryTrackingString",
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "browseUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "categoryCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Seller",
                "kind": "LinkedField",
                "name": "seller",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "pdpMetaItems",
                "kind": "LinkedField",
                "name": "pdpMeta",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "topQuery",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "localizedPdpUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ItemClassification",
                "kind": "LinkedField",
                "name": "classification",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "creationDate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ItemCreator",
                "kind": "LinkedField",
                "name": "creators",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "attribution",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Creator",
                    "kind": "LinkedField",
                    "name": "creator",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isArt",
                "storageKey": null
              },
              (v10/*: any*/),
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "AuctionSolution",
                "kind": "LinkedField",
                "name": "relevantAuctionSolution",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasReserve",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reserveMet",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuctionBidsConnection",
                    "kind": "LinkedField",
                    "name": "bids",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalResults",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "relevantAuctionSolution(page:\"searchAndBrowse\")"
              },
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "AuctionLot",
                "kind": "LinkedField",
                "name": "relevantAuctionLot",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endDate",
                    "storageKey": null
                  }
                ],
                "storageKey": "relevantAuctionLot(page:\"searchAndBrowse\")"
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "isTrade",
                    "value": false
                  },
                  (v11/*: any*/)
                ],
                "concreteType": "ItemDisplayPriceType",
                "kind": "LinkedField",
                "name": "displayPrice",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "textType",
                    "storageKey": null
                  },
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "quantityDisplay",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "percentageOff",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amountType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "showPriceLabel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "showPriceVariability",
                    "storageKey": null
                  }
                ],
                "storageKey": "displayPrice(isTrade:false,page:\"searchAndBrowse\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isAuctionEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isStorefrontOnly",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isMultiSku",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isRingItem",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "VariableAttribute",
                "kind": "LinkedField",
                "name": "variableAttributes",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VariableAttributeSortType",
                    "kind": "LinkedField",
                    "name": "variableAttributeData",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SwatchSort",
                        "kind": "LinkedField",
                        "name": "swatchSort",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Swatch",
                            "kind": "LinkedField",
                            "name": "swatch",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "imageUrl",
                                "storageKey": null
                              },
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "originalDisplayPrice",
                "args": (v12/*: any*/),
                "concreteType": "ItemDisplayPriceType",
                "kind": "LinkedField",
                "name": "displayPrice",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ConvertedAmountList",
                    "kind": "LinkedField",
                    "name": "originalConvertedAmountList",
                    "plural": true,
                    "selections": (v13/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": "displayPrice(page:\"searchAndBrowse\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ItemPricing",
                "kind": "LinkedField",
                "name": "pricing",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FinancialAmount",
                    "kind": "LinkedField",
                    "name": "retailPrice",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "QuickViewDisplayType",
                "kind": "LinkedField",
                "name": "quickViewDisplay",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": "mobileTitle",
                    "args": (v15/*: any*/),
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": "title(formatType:\"MOBILE\")"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "QuickViewParagraph",
                    "kind": "LinkedField",
                    "name": "paragraphs",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "key",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": null
                      },
                      {
                        "alias": "mobileText",
                        "args": (v15/*: any*/),
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": "text(formatType:\"MOBILE\")"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "QuickViewCreator",
                    "kind": "LinkedField",
                    "name": "creators",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "QuickViewCategorySegments",
                    "kind": "LinkedField",
                    "name": "paragraphAttributeNames",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "period",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "origin",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "style",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "periodPrefix",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ItemMeasurement",
                "kind": "LinkedField",
                "name": "measurement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "addLabel",
                        "value": true
                      },
                      {
                        "kind": "Literal",
                        "name": "fields",
                        "value": [
                          "height",
                          "width",
                          "depth",
                          "diameter",
                          "length"
                        ]
                      },
                      {
                        "kind": "Literal",
                        "name": "separator",
                        "value": " "
                      }
                    ],
                    "concreteType": "ItemDisplayMeasurementType",
                    "kind": "LinkedField",
                    "name": "display",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unit",
                        "storageKey": null
                      },
                      (v16/*: any*/)
                    ],
                    "storageKey": "display(addLabel:true,fields:[\"height\",\"width\",\"depth\",\"diameter\",\"length\"],separator:\" \")"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ItemSize",
                    "kind": "LinkedField",
                    "name": "size",
                    "plural": false,
                    "selections": [
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "isInEurope",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "regionName",
                    "value": "Europe"
                  }
                ],
                "kind": "ScalarField",
                "name": "isInRegion",
                "storageKey": "isInRegion(regionName:\"Europe\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Address",
                "kind": "LinkedField",
                "name": "address",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayCountry",
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "productPhotos",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "limit",
                    "value": 1
                  }
                ],
                "concreteType": "ItemPhoto",
                "kind": "LinkedField",
                "name": "photos",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "placeholder",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "smallPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "masterOrZoomPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ItemPhotoVersion",
                    "kind": "LinkedField",
                    "name": "versions",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "webPath",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": "photos(limit:1)"
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "condition": "loadPortfolioData",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "itemIds",
                    "value": []
                  },
                  (v17/*: any*/),
                  (v4/*: any*/)
                ],
                "concreteType": "PortfolioItemV2",
                "kind": "LinkedField",
                "name": "portfolioItemMatches",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "portfolioId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "portfolioItemId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Item",
                    "kind": "LinkedField",
                    "name": "item",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "userPortfolios",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1
                  },
                  (v17/*: any*/),
                  (v4/*: any*/)
                ],
                "concreteType": "PortfoliosConnection",
                "kind": "LinkedField",
                "name": "portfolios",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PortfoliosEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PortfolioV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-finding/480.0.0-2024-09-18T15:35:19.730Z/RecommendedItemsCarouselViewerRefetchQuery",
    "metadata": {},
    "name": "RecommendedItemsCarouselViewerRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "03d12ee7a5fb47be50563e6750039690";

export default node;
